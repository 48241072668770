import React from 'react';
import { bool, node, string } from 'prop-types';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import classNames from 'classnames/bind';
import styles from './hero-personalized.module.scss';

export const HeroPersonalizedWrapper = ({
  backgroundColor = 'white',
  children,
  isVisible = false,
  usePlaceholder = true,
}) => {
  const cx = classNames.bind(styles);

  const placeholderStyles = cx({
    'hero-personalized__wrapper': isVisible,
    'hero-personalized__hidden': !isVisible,
  });
  const backgroundStyles = cx({
    'hero-personalized__background': isVisible,
    'hero-personalized__hidden': !isVisible,
  });

  return (
    <div
      style={{
        position: 'relative',
        ...(!isVisible && { display: 'flex', flexDirection: 'column' })
      }}
      data-component="InnervateHero"
    >
      {usePlaceholder && <Placeholder className={placeholderStyles} />}
      <div className={backgroundStyles} style={{ backgroundColor }} />
      {children}
    </div>
  );
};

HeroPersonalizedWrapper.propTypes = {
  backgroundColor: string,
  children: node.isRequired,
  isVisible: bool,
  usePlaceholder: bool,
};
