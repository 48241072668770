/* eslint-disable react/jsx-pascal-case */
import React, { memo, useMemo } from 'react';
import { bool, shape, string, arrayOf } from 'prop-types';
import classNames from 'classnames/bind';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { AppContext } from '@thd-nucleus/app-render';
import { CustomerContext, useThdCustomer } from '@thd-olt-functional/customer-information';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { declareContexts } from '@thd-olt-component-react/hydrator';
import { Typography } from '@one-thd/sui-atomic-components';
import {
  getContentfulTargetingIds,
  useVariationIds,
} from '@thd-olt-component-react/personalization-utils';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import { componentMapper } from '../dataModel/componentMapper';
import { parseContent, parseSponsoredContent } from '../utils/utilities';
import { useGetUrlParameter } from '../hooks/useGetUrlParameter';
import styles from './flexible-layout.scss';
import { FlexibleRenderResolver } from './components/FlexibleRenderResolver';
import { previewComponentMapper } from '../dataModel/previewComponentMapper';

/**
 * Main job of this component is to just render the various harmony components only when necessary (see react pure
 * components).
 */

export const FlexibleLayoutRenderer = memo(({
  canonicalURL,
  data,
  filterBreadcrumbs,
  pageId,
  customerType,
  storeId,
  pageTypeComponents,
  search,
  thdSEOBotDetection,
  isPreviewLink,
  isLivePreview = false
}) => {
  declareContexts([AppContext, CustomerContext]);
  const thdCustomer = useThdCustomer();
  const cx = classNames.bind(styles);
  const layouts = data?.layouts;
  const content = layouts?.content;
  const experienceHasSideNav = !!content?.sideNavigation?.id;

  const { mboxes, campaignIds, innervateTags } = getContentfulTargetingIds({ content,
    containerKeys: {
      SpotlightContainer: 'spotlightList'
    } });

  const isReady = mboxes.length > 0 || campaignIds.length > 0 || innervateTags.length > 0;

  // adobe's Campaign Management Multi-Media Channel parameter to be passed to Citi, so that we can
  // track credit card campaigns through application completions - for Spotlight and Hero components
  const cmMmcParam = useGetUrlParameter('cm_mmc');

  const { variationIds, renderDefault } = useVariationIds({
    mboxes,
    campaignIds,
    innervateTags,
    isReady,
  });

  const theme = useTheme(FlexibleLayoutRenderer);
  const { hideInternalMarketingBanners } = theme.props;

  const customProps = {
    ComponentSelector: (component) => ({
      componentMap: isLivePreview ? previewComponentMapper.getComponentMap() : componentMapper.getComponentMap(),
      defaultComponent: component.defaultComponent,
      variations: component.variations,
      variationIds: variationIds || [],
      renderDefault,
      isSeoBot: thdSEOBotDetection,
      targetingType: component.targetingType,
      customPropMap: {
        Spotlight: {
          showDescription: true,
          cmMmcParam
        },
        SpotlightContainer: {
          showDescription: true,
          cmMmcParam
        },
        HeroPersonalized: {
          thdPageId: pageId,
          searchParams: search,
          isSeoBot: thdSEOBotDetection,
          customerType
        },
        Hero: {
          hasSidenav: true,
          cmMmcParam
        }
      }
    }),
    EndCap: () => ({
      storeId,
      customerType: customerType.toUpperCase(),
      pageUrl: `/${pageId}`
    }),
    SeoLinks: () => ({
      path: canonicalURL
    }),
    Spotlight: () => ({
      showDescription: true,
      cmMmcParam,
      shouldUseContentPlayer: true
    }),
    SpotlightContainer: () => ({
      showDescription: true,
      cmMmcParam,
      variationIds: variationIds || [],
      renderDefault,
      isSeoBot: thdSEOBotDetection,
    }),
    HeroPersonalized: () => ({
      thdPageId: pageId,
      searchParams: search,
      isSeoBot: thdSEOBotDetection,
      customerType
    }),
    Hero: () => ({
      cmMmcParam
    }),
    Resource: () => ({
      shouldUseContentPlayer: true
    }),
    PromoVisualNavigation: () => ({
      experienceHasSideNav: true,
      enableTheme: true
    }),
    ProductShelf: () => ({
      showTooFewProductsNotice: isPreviewLink
    }),
    VisualNavigation: () => ({
      enableTheme: true
    }),
  };

  const { Content } = useMemo(() => parseContent({
    data,
    componentMapper: isLivePreview ? previewComponentMapper : componentMapper,
    customProps,
    layout: 'universal',
  }), [data, variationIds, renderDefault, search]);

  const { Sponsored } = useMemo(() => parseSponsoredContent({
    data,
    componentMapper: isLivePreview ? previewComponentMapper : componentMapper,
    customProps,
    layout: 'universal',
  }), [data]);

  const pageTitle = content?.title;
  const descriptionText = content?.descriptionNew?.descriptionText || '';
  const descriptionTitle = content?.descriptionNew?.title || '';
  const bottomCarouselCustomRenderId = layouts?.content?.sponsoredBottomCarousel?.customRenderId;
  const middleBannerCustomRenderId = content?.sponsoredMiddleBanner?.customRenderId;
  const skyscraperBannerCustomRenderId = content?.sponsoredSkyscraperBanner?.customRenderId;
  const topBannerCustomRenderId = layouts?.content?.sponsoredTopBanner?.customRenderId;
  const sponsoredBrowserId = thdCustomer?.mcvisID || '';
  const isCategoryPage = layouts?.type === 'Category';

  let contentfulDefaultBanner = {};

  if (content?.sponsoredMiddleBanner !== null) {
    contentfulDefaultBanner = content?.sponsoredMiddleBanner;
  } else if (content?.sponsoredTopBanner !== null) {
    contentfulDefaultBanner = content?.sponsoredTopBanner;
  }

  const { landingPageBreadcrumbs, landingPageMetadata } = pageTypeComponents;

  return (
    <Row name="flexible-layout-manager">
      <ErrorBoundary name="metadata">
        {landingPageMetadata}
      </ErrorBoundary>
      <Row className="isBound">
        <Col xs="12" className={cx('flexible-layout__no-bottom-padding')}>
          <ErrorBoundary name="breadcrumbs">
            {landingPageBreadcrumbs}
          </ErrorBoundary>
        </Col>
        <Col xs="12" className={cx('flexible-layout__no-top-bottom-padding')}>
          <ErrorBoundary name="sponsored-top-banner">
            <Sponsored.sponsoredTopBanner
              browserId={sponsoredBrowserId}
              slugId={pageId}
              customRenderId={topBannerCustomRenderId}
              contentfulDefaultBanner={contentfulDefaultBanner}
              schema="cat_sponsored"
            />
          </ErrorBoundary>
        </Col>
        <Col xs="12">
          {pageTitle && (
            <ErrorBoundary name="heading">
              <Typography
                variant="h1"
                weight="display"
                height="snug"
                color="primary"
                uppercase
                data-contentful-entry-id={layouts?.content?.id}
                data-contentful-field-id="title"
              >
                {pageTitle}
              </Typography>
            </ErrorBoundary>
          )}
          <Row className={`isBound ${!experienceHasSideNav && ' sui-justify-center'}`}>
            {experienceHasSideNav && (
              <Col sm="2" md="2" lg="2">
                <ErrorBoundary name="side-navigation">
                  <div className={cx('flexible-layout__side-navigation')}>
                    <Content.sideNavigation />
                  </div>
                </ErrorBoundary>
                {isCategoryPage && (
                  <ErrorBoundary name="sponsored-skyscraper-banner">
                    <Sponsored.sponsoredSkyscraperBanner
                      browserId={sponsoredBrowserId}
                      slugId={pageId}
                      customRenderId={skyscraperBannerCustomRenderId}
                      schema="cat_sponsored"
                    />
                  </ErrorBoundary>
                )}
              </Col>
            )}
            <Col xs="12" sm="10" md="10" lg="10">
              <ErrorBoundary name="hero-carousel">
                <Content.heroCarousel />
              </ErrorBoundary>
              {Content.flexibleCluster1?.map((SubSection, key) => (
                <FlexibleRenderResolver
                  componentId={`flexible-cluster-1-${key}`}
                  key={`flexible-cluster-1-${key}`}
                  noTopMargin={key === 0 && !content.heroCarousel}
                >
                  <SubSection />
                </FlexibleRenderResolver>
              ))}
              <FlexibleRenderResolver componentId="endcap">
                <Content.endCap />
              </FlexibleRenderResolver>
              {!hideInternalMarketingBanners && (
                <FlexibleRenderResolver componentId="internal-marketing-banner-1">
                  <Content.internalMarketingBanner1 />
                </FlexibleRenderResolver>
              )}
              <FlexibleRenderResolver componentId="sponsored-middle-banner">
                <Sponsored.sponsoredMiddleBanner
                  slugId={pageId}
                  customRenderId={middleBannerCustomRenderId}
                  contentfulDefaultBanner={contentfulDefaultBanner}
                  schema="cat_sponsored"
                />
              </FlexibleRenderResolver>
              {!hideInternalMarketingBanners && (
                <FlexibleRenderResolver componentId="internal-marketing-banner-2">
                  <Content.internalMarketingBanner2 />
                </FlexibleRenderResolver>
              )}
              <FlexibleRenderResolver componentId="special-buy-banner">
                <Content.specialBuyOfTheDay specialBuyType="sbotd" />
              </FlexibleRenderResolver>
              {Content.flexibleCluster2?.map((SubSection, key) => (
                <FlexibleRenderResolver componentId={`flexible-cluster-2-${key}`} key={`flexible-cluster-2-${key}`}>
                  <SubSection />
                </FlexibleRenderResolver>
              ))}
              <FlexibleRenderResolver componentId="product-shelf">
                <Content.productShelf />
              </FlexibleRenderResolver>
              {Content.flexibleCluster3?.map((SubSection, key) => (
                <FlexibleRenderResolver componentId={`flexible-cluster-3-${key}`} key={`flexible-cluster-3-${key}`}>
                  <SubSection />
                </FlexibleRenderResolver>
              ))}
              <FlexibleRenderResolver componentId="sponsored-bottom-carousel">
                <Sponsored.sponsoredBottomCarousel
                  plaLocation="sponsoredCarouselLandingPage"
                  browserId={sponsoredBrowserId}
                  breadCrumbs={filterBreadcrumbs}
                  customRenderId={bottomCarouselCustomRenderId}
                  pageContext={{
                    schema: 'cat_sponsored'
                  }}
                  nValue={pageId}
                />
              </FlexibleRenderResolver>
              <ErrorBoundary name="description">
                <div className={cx('flexible-layout__static-section')}>
                  {Content.richText?.map((DescriptionNew, key) => (
                    <FlexibleRenderResolver componentId={`richText-${key}`} key={`richText-${key}`}>
                      <DescriptionNew title={descriptionTitle} description={descriptionText} />
                    </FlexibleRenderResolver>
                  ))}
                </div>
              </ErrorBoundary>
              <FlexibleRenderResolver componentId="related-search">
                <Content.relatedSearchesAndProducts navParam={pageId} />
              </FlexibleRenderResolver>
              <FlexibleRenderResolver componentId="seo-links">
                {canonicalURL && <Content.seoLinks />}
              </FlexibleRenderResolver>
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
});

FlexibleLayoutRenderer.displayName = 'FlexibleLayoutRenderer';

FlexibleLayoutRenderer.propTypes = {
  canonicalURL: string,
  data: shape({}).isRequired,
  filterBreadcrumbs: arrayOf(shape(
    {
      browseUrl: string,
      label: string,
    },
  )),
  pageTypeComponents: shape({}).isRequired,
  pageId: string.isRequired,
  search: string,
  storeId: string.isRequired,
  customerType: string.isRequired,
  thdSEOBotDetection: bool,
  isPreviewLink: bool,
  isLivePreview: bool
};

FlexibleLayoutRenderer.defaultProps = {
  canonicalURL: null,
  filterBreadcrumbs: null,
  search: '',
  thdSEOBotDetection: false,
  isPreviewLink: false
};

FlexibleLayoutRenderer.themeProps = {
  hideInternalMarketingBanners: bool,
};

FlexibleLayoutRenderer.defaultThemeProps = {
  hideInternalMarketingBanners: false
};