export const FULFILLMENT_METHODS = {
  STH: 'ShipToHome',
  BODFS: 'DeliverFromStore',
  BOPIS: 'BOPIS',
  STORE: 'ShipToStore',
  APPLIANCE: 'DirectDelivery',
  BOSS: 'ShipToStore',
  EXPRESS_DELIVERY: 'express delivery',
  STH_FFM: 'sth'
};

export const CUSTOMER_TYPES = {
  B2B: 'B2B',
  B2C: 'B2C',
  B2X: 'B2X'
};

export const INVENTORY_SEARCH_ARRAY = {
  ShipToHome: ['delivery', 'sth'],
  DeliverFromStore: ['delivery', 'express delivery'],
  BOPIS: ['pickup', 'bopis'],
  ShipToStore: ['pickup', 'boss']
};

export const SHIPPING_MODE_GROUPS = {
  PARCEL: 'PARCEL'
};

export const MAJOR_APPLIANCE_DELIVERY = {
  BACK_ORDERED: 'BACK_ORDERED',
  AVAILABLE: 'AVAILABLE',
  OOS_ETA_UNAVAILABLE: 'OOS_ETA_UNAVAILABLE',
  OOS_ALT_MODEL: 'OOS_ALT_MODEL',
  UNAVAILABLE: 'UNAVAILABLE'
};

export const CHECK_NEARBY_STORES = 'Check Nearby Stores';
export const MAJOR_APPLIANCE = 'MAJOR_APPLIANCE';
export const APPLIANCE_DEPARTMENT = '29';
export const APPLIANCE_ERROR_MSG = {
  UNAVAILABLE_MSG: 'Appliance delivery isn\'t offered to your area. '
    + 'Your nearest store may be able to offer other arrangements.',
  EXCHANGE_UNAVAILABLE_MSG: 'Appliance delivery isn\'t offered to your area. '
    + 'Your nearest Home Depot store may be able to offer other arrangements.',
  GENERAL_ERROR: 'Sorry, an error occurred.'
};
export const DEFAULT_PRODUCTION_URL_ORIGIN = 'https://www.homedepot.com';
export const NON_MAJOR_APPLIANCE_CLASS = ['7', '8', '13', '15'];

export const MERCHANDISE = 'MERCHANDISE';
// external components are top level keys;
export const FOREIGN_LIFE_CYCLE_EVENTS = {
  productAddOns: {
    attachLaborChange: 'attach-labor.change',
    bodfsAssemblyChange: 'bodfs-assembly.change'
  },
  subscriptionAdded: 'attach-subscription.change'
};
export const LIVE_GOODS_OUT_OF_SEASON_NEW = 'This Plant is Out of Season';
export const LIVE_GOODS_IN_SEASON_NEW = 'This Plant is in Season';
export const COVID_19_CUTOFF_TIME = 14;
export const LIVE_GOODS_DEPARTMENT = '28';
export const LIVE_GOODS_CLASS = '8';

export const GCC_COUNTER_TOPS = 'custom-countertops';
export const CONFIGURABLE_BLINDS = 'CONFIGURABLE_BLINDS';

export const LONG_MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
export const SHORT_MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const LONG_WEEK_NAMES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];
export const SHORT_WEEK_NAMES = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const MORE_DELIV_OPTIONS_TEXT = 'More delivery methods in checkout';

export const BODFS_CAR_SCHEDULE_DELIV = {
  TITLE_TEXT1: 'Get it delivered ',
  TITLE_TEXT2: ' for as low as $',
  BODY_TEXT: 'Schedule your delivery in checkout.'
};

export const SIX_HOUR_CAR_SCHEDULE_DELIV = {
  TITLE_TEXT: 'Get it within 6 hours.',
  BODY_TEXT: 'Choose Scheduled Delivery in checkout.'
};

export const SCHEDULE_DELIV = {
  TITLE_TEXT: 'Get it as soon as ',
  BODY_TEXT: 'Schedule your delivery in checkout.'
};

export const SCHEDULE_DELIV_LATER = {
  TITLE_TEXT: 'Prefer to schedule your delivery?',
  BODY_TEXT: 'Select your delivery date in checkout'
};

export const APPLIANCE_SHIPPING_MIN_NOT_MET = {
  FREE_TEXT: 'FREE',
  TITLE_TEXT: 'delivery with a $396 or more appliance purchase.',
  BODY_TEXT: 'Discount applied in cart.'
};

export const APPLIANCE_SHIPPING_MIN_MET = {
  TITLE_TEXT: 'Need to schedule your appliance delivery?',
  BODY_TEXT: 'Select your delivery date in checkout',
  BODY_TEXT_ADDITIONAL: 'Delivery cost applied in cart.'
};

export const BOSS_DOMINANT = {
  partOne: 'Just enter the quantity you need and we will ship it to',
  partTwo: 'for free pickup.'
};

export const CHECK_NEARBY_STORE = 'check nearby store';

export const CATEGORY = {
  PLUMBING_REPAIR: 'PLUMBING_REPAIR',
  WATER_HEATER_REPAIR: 'WATER_HEATER_REPAIR'
};

export const CLEARANCE_MESSAGE = {
  partOne: ' This item isn\'t eligible for online order \nPickup, but it is on Clearance at ',
  partTwo: '\nWhile supplies last. ',
  partThree: 'See low price'
};

export const DEFAULT_APPLIANCE_DELIVERY_THRESHOLD = 396;
