/* eslint-disable tailwindcss/no-custom-classname */
import React, { useState } from 'react';
import { string, bool } from 'prop-types';
import { Play, Pause } from '@one-thd/sui-icons';
import styles from './video-hero-image.scss';

const VideoHeroImage = ({
  imageUrl = '', altText = '', fullWidth = false, brandHero = false, videoClasses = ''
}) => {

  const [videoPaused, setVideoPaused] = useState(false);

  const handlePlayPause = () => {
    const videoElement = document.querySelector(`source[src="${imageUrl}"]`)?.parentNode;
    setVideoPaused(!videoPaused);
    /* eslint-disable-next-line no-unused-expressions */
    videoPaused ? videoElement?.play() : videoElement?.pause();
  };

  const videoButton = (
    <button
      type="button"
      /* eslint-disable-next-line max-len */
      className="hero-video-button sui-absolute sui-bottom-4 sui-right-4 sui-flex sui-justify-end sui-items-end"
      aria-label="pause or play video"
      onClick={() => {
        handlePlayPause();
      }}
      onKeyUp={(event) => {
        event.stopPropagation();
      }}
    >
      <div
        data-testid="play-button"
        className={`play-button
                    sui-h-12
                    sui-w-12
                    sui-justify-center
                    sui-items-center
                    sui-bg-inactive
                    sui-rounded-full
                    ${videoPaused ? 'sui-flex' : 'sui-hidden'}`}
      >
        <div className="play-button-hover sui-pl-1"><Play color="brand" size="small" /></div>
        <div className="play-button-no-hover sui-pl-1"><Play color="primary" size="small" /></div>
      </div>
      <div
        className={`pause-button
                    sui-h-12
                    sui-w-12
                    sui-justify-center
                    sui-items-center
                    sui-bg-inactive
                    sui-rounded-full
                    ${videoPaused ? 'sui-hidden' : 'sui-flex'}`}
      >
        <div className="pause-button-hover"><Pause color="brand" size="small" /></div>
        <div className="pause-button-no-hover"><Pause color="primary" size="small" /></div>
      </div>
    </button>
  );
  const heroImage = (
    <div className={`video-hero-image sui-overflow-hidden sui-relative ${fullWidth
      ? 'sui-w-full' : ''}${brandHero ? ' sui-row-start-1 md:sui-row-start-auto' : ''}`}
    >
      <video
        className={`${videoClasses}`}
        aria-label={`${altText}`}
        autoPlay
        playsInline
        muted
        loop
        controls=""
      >
        <source src={imageUrl} type="video/mp4" />
      </video>
      {videoButton}
    </div>
  );

  return heroImage;
};

VideoHeroImage.propTypes = {
  imageUrl: string,
  altText: string,
  fullWidth: bool,
  brandHero: bool,
  videoClasses: string,
};

export { VideoHeroImage };