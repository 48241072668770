import { createTheme } from '@one-thd/sui-atomic-components';

const ThdDefaultLightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'neutral-0'
    }
  }
});

export { ThdDefaultLightTheme };