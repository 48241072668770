/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { bool, instanceOf, number, shape, string } from 'prop-types';
import { LoadingPlaceholder } from '@thd-olt-component-react/loading-placeholder';
import { QueryContext, QueryProvider, extend } from '@thd-nucleus/data-sources';
import { ThemeProvider } from '@one-thd/sui-atomic-components';
import { getThemeByName } from '@thd-olt-component-react/stencil-themes';
import { usePsSearchModel } from '../hooks/usePsSearchModel';
import { ProductShelfCarousel } from './ProductShelfCarousel';
import classNames from 'classnames/bind';
import styles from './product-shelf-container.scss';
import { Typography, Link } from '@one-thd/sui-atomic-components';

const cx = classNames.bind(styles);

/**
 * @group Components
 * Displays Product-Shelf component
 */

const ProductShelfContainer = (props) => {

  const { channel, linkType, minDpdCount, showTooFewProductsNotice, theme } = props;
  const { isClientResolved, defaultVariables } = useContext(QueryContext);
  const { isBrandPricingPolicyCompliant = false } = defaultVariables?.current;
  const startIndex = 0;
  const pageSize = 24;
  const {
    skus,
    shopAllLink,
    categoryLabel,
    storeId,
    storeZip,
    totalProducts = 0,
    loading
  } = usePsSearchModel({
    ...props,
    pageSize,
    storefilter: 'ALL',
    startIndex
  });

  const isMobile = channel === 'mobile';
  const isSSR = typeof window === 'undefined';
  const showProductShelf = totalProducts > minDpdCount && !isSSR;

  let showArrows = isMobile ? totalProducts > 1 : totalProducts > 5;
  const carouselConfig = {
    breakpoints: {
      sm: {
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      md: {
        slidesPerView: 4,
        slidesPerGroup: 4
      },
      lg: {
        slidesPerView: 4,
        slidesPerGroup: 4
      },
      xl: {
        slidesPerView: 5,
        slidesPerGroup: 5
      }
    },
    peek: false
  };
  const skipFn = ({ skip, attributes, queryName }) => {
    if (queryName !== 'product') {
      return skip;
    }
    const isResolved = isClientResolved({ queryName: 'psSearchModel' });
    if (attributes.product.fulfillment && !isResolved) {
      return true;
    }

    return skip;
  };

  const resolvedProductSearch = isClientResolved({ queryName: 'psSearchModel' });
  const hideProductShelf = !showProductShelf && resolvedProductSearch && !loading;

  return (
    <div className="sui-bg-primary sui-text-primary">
      <ThemeProvider theme={getThemeByName(theme)} ssr>
      <div
        className={cx('sui-bg-primary sui-text-primary product-shelf sui-w-full sui-min-h-[523px] sui-text-left', {'sui-p-4' : !!theme}, { 'product-shelf__hide sui-hidden': hideProductShelf })}
        data-component="ProductShelf"
      >
        {!showProductShelf &&
          (
            <>
              <div className="product-shelf__placeholder-label sui-pb-[5px]">
                <LoadingPlaceholder
                  type="rect"
                  ready={false}
                  showLoadingAnimation
                  color="#E0E0E0"
                  style={{ height: "40px", width: "100%" }}
                >
                  Label Placeholder
                </LoadingPlaceholder>
              </div>
              <div>
                <div className="product-shelf__carousel--wrapper sui-p-0 sui-overflow-y-auto sui-flex-[1] no-arrows-displayed">
                  <ProductShelfCarousel carouselConfig={carouselConfig} showPlaceholder />
                </div>
              </div>
            </>
          )
        }
        {showProductShelf &&
          (
            <>
              <div className="product-shelf__label sui-flex-wrap sui-flex sui-items-center sui-justify-between sui-mb-4">
                <Typography variant="h2" height="snug" uppercase weight="display"
                  data-contentful-field-id="title" data-contentful-entry-id={props.componentId}
                >{categoryLabel}</Typography>
                {shopAllLink !== '' && (
                  <Link
                    href={shopAllLink}
                  >
                    Shop All
                  </Link>
                )}
              </div>
              <div className="product-shelf__dynamic-aisle sui-flex sui-bg-transparent sui-rounded-[3px] sui-bg-no-repeat sui-bg-cover sui-bg-center sm:sui-pl-[10px] max-[640px]:sui-flex-col">
                <div className={cx('product-shelf__carousel--wrapper sui-p-0 sui-overflow-y-auto sui-flex-[1] max-[640px]:sui-m-[7px]', { 'no-arrows-displayed': !showArrows })}>
                  <QueryProvider
                    dataSource="searchNav"
                    cacheKey="dpdSearchNav"
                    defaultVariables={{
                      storeId,
                      zipCode: storeZip,
                      skipKPF: true,
                      skipSubscribeAndSave: true,
                      isBrandPricingPolicyCompliant
                    }}
                    skip={skipFn}
                  >
                    <ProductShelfCarousel
                      channel={channel}
                      skus={skus}
                      storeId={storeId}
                      storeZip={storeZip}
                      linkType={linkType}
                      carouselConfig={carouselConfig}
                      theme={theme}
                    />
                  </QueryProvider>
                </div>
              </div>
            </>
          )
        }
      </div>
      </ThemeProvider>
      {showTooFewProductsNotice && hideProductShelf &&
        (
          <div
            className={cx('product-shelf sui-w-full sui-text-left sui-text-danger')}
            data-component="ProductShelf"
          >
            <p>Not enough valid products ({totalProducts} &lt; {minDpdCount}) to display Product Shelf module</p>
          </div>
        )
      }
    </div>
  );
};

ProductShelfContainer.displayName = 'ProductShelfContainer';

ProductShelfContainer.dataModel = extend(ProductShelfCarousel);

ProductShelfContainer.propTypes = {
  componentId: string,
  channel: string,
  host: string,
  linkType: string,
  minDpdCount: number,
  opts: shape({
    contentfulTitle: string,
    navParam: string,
    itemIds: instanceOf(Array),
    sortBy: string,
    sortOrder: string
  }),
  showTooFewProductsNotice: bool,
  theme: string,
  storeId: string
};

ProductShelfContainer.defaultProps = {
  componentId: '',
  channel: 'desktop',
  host: 'https://www.homedepot.com',
  linkType: '_self',
  minDpdCount: 4,
  opts: {},
  showTooFewProductsNotice: false,
  theme: null,
  storeId: undefined
};

export { ProductShelfContainer };
