/**
 * Note:
 * This file should be synced with the corresponding layout in Contenful CMS.
 */

import { CalloutCard } from '@thd-olt-component-react/callout_card';
import { CapabilityCard } from '@thd-olt-component-react/capability_card';
import { ContentAccordion } from '@thd-olt-component-react/content-accordion';
import {
  arrayOf,
  string,
  fragment,
  namedFragment,
  shape,
  bool,
  params,
  customType
} from '@thd-nucleus/data-sources';
import { Hero, HeroPersonalized } from '@thd-olt-component-react/hero';
import { MedioInline, PiccolaPencil, MedioComposableBanner, PiccolaComposableBanner } from '@thd-olt-component-react/internal-marketing-banner';
import { NewspaperModule } from '@thd-olt-component-react/newspaper-module';
import { Resource } from '@thd-olt-component-react/resource';
import { ContentGalleryCard } from '@thd-olt-component-react/content-gallery-card';
import { SideNavigation } from '@thd-olt-component-react/side-navigation';
import { Spotlight, SpotlightContainer } from '@thd-olt-component-react/spotlight';
import { VisualNavigation, PromoVisualNavigation } from '@thd-olt-component-react/visual-navigation';
import { ProductShelf } from '@thd-olt-component-react/product-shelf';
import {
  DynamicComponentSelector,
  HeroComponentSelector,
  ResourceComponentSelector,
  SpotlightComponentSelector
} from '../dataModel/personalization';

// EmtDescription doesn't have a data model, so we need to define it here. Component Class is 'Description_New' as defined in the CMS.
const EmtDescription = {};
EmtDescription.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['Description_New']).isRequired(),
  }).shape({
    Description_New: fragment().shape({
      id: string(),
      title: string(),
      descriptionText: string()
    }),
  }),
};

// TODO: this is actually just the Section Type, but specifically the one used
// in dynamicSections / flexibleCluster
// array support for named fragments is needed to convert this.
const dynamicSectionsFragment = {
  __typename: string(),
  id: string(),
  title: string(),
  theme: string(),
  isCarousel: bool(),
  itemTheme: string(),
  components: arrayOf(shape({
    ...CalloutCard.dataModel.component,
    ...CapabilityCard.dataModel.component,
    ...ContentAccordion.dataModel.component,
    ...ContentGalleryCard.dataModel.component,
    ...DynamicComponentSelector.dataModel.component,
    ...PromoVisualNavigation.dataModel.component,
    ...Resource.dataModel.component,
    ...Spotlight.dataModel.component,
    ...SpotlightContainer.dataModel.specializedContainer,
    ...VisualNavigation.dataModel.component,
    ...EmtDescription.dataModel.component,
    ...NewspaperModule.dataModel.component,
    ...MedioInline.dataModel.component,
    ...MedioComposableBanner.dataModel.component,
    ...PiccolaPencil.dataModel.component,
    ...PiccolaComposableBanner.dataModel.component
  })),
};

const spotlightSectionNamedFragment = namedFragment({
  inline: false,
  fragmentType: 'Section',
  fragmentAlias: 'SpotlightSection'
}).shape({
  id: string(),
  title: string(),
  theme: string(),
  isCarousel: bool(),
  itemTheme: string(),
  components: arrayOf(shape({
    ...Spotlight.dataModel.component,
    ...SpotlightComponentSelector.dataModel.component,
    ...SpotlightContainer.dataModel.specializedContainer,
  })),
});

const resourcesSectionNamedFragment = namedFragment({
  inline: false,
  fragmentType: 'Section',
  fragmentAlias: 'ResourceSection'
}).shape({
  id: string(),
  title: string(),
  theme: string(),
  isCarousel: bool(),
  itemTheme: string(),
  components: arrayOf(shape({
    ...Resource.dataModel.component,
    ...ResourceComponentSelector.dataModel.component,
  })),
  __typename: string(),
});

const baseLayoutFragment = {
  // TODO: see about getting nullability shapes
  // consistent with universal
  id: string(),
  title: string(),
  endCap: shape({
    id: string(),
    theme: string(),
    __typename: string()
  }),
  // Because Universal uses Section and the others use HeroCarousel
  // we cant use one fragment for all four.
  heroCarousel: shape({
    id: string(),
    isCarousel: bool(),
    isAutoplay: bool(),
    components: arrayOf(
      shape({
        ...Hero.dataModel.component,
        ...HeroComponentSelector.dataModel.component,
        ...HeroPersonalized.dataModel.heroPersonalized
      })
    ),
  }),
  productShelf: shape({ ...ProductShelf.dataModel.component }),
  relatedSearchesAndProducts: shape({
    id: string()
  }),
  seoLinks: shape({
    id: string()
  }),
  specialBuyOfTheDay: shape({
    __typename: string(),
    id: string()
  }),
  spotlightSection: spotlightSectionNamedFragment,
  sponsoredBottomCarousel: shape({
    __typename: string(),
    customRenderId: string(),
    id: string(),

  }),
  sponsoredMiddleBanner: namedFragment({
    inline: false,
    fragmentType: 'SponsoredMiddleBanner',
    fragmentAlias: 'SponsoredMiddle'
  }).shape({
    __typename: string(),
    customRenderId: string(),
    imgDesktop: string(),
    imgMobile: string(),
    clickthruUrl: string(),
    specialDefaultBanner: shape({
      imgDesktop: string(),
      imgMobile: string(),
      clickthruUrl: string(),
      useSpecialBanner: bool()
    }),
    id: string(),
  }),
  sponsoredTopBanner: namedFragment({
    inline: false,
    fragmentType: 'SponsoredTopBanner',
    fragmentAlias: 'SponsoredTop'
  }).shape({
    __typename: string(),
    customRenderId: string(),
    imgDesktop: string(),
    imgMobile: string(),
    clickthruUrl: string(),
    specialDefaultBanner: shape({
      imgDesktop: string(),
      imgMobile: string(),
      clickthruUrl: string(),
      useSpecialBanner: bool()
    }),
    id: string(),
  }),
};

const gallerySectionFragment = {
  id: string(),
  title: string(),
  theme: string(),
  isCarousel: bool(),
  itemTheme: string(),
  components: arrayOf(
    shape({ ...ContentGalleryCard.dataModel.component })
  ),
  __typename: string(),
};

const visNavSectionNamedFragment = namedFragment({
  inline: false,
  fragmentType: 'Section',
  fragmentAlias: 'VisnavSection'
}).shape({
  id: string(),
  title: string(),
  theme: string(),
  isCarousel: bool(),
  itemTheme: string(),
  components: arrayOf(shape({ ...VisualNavigation.dataModel.component })),
});

const promoVisNavSectionNamedFragment = namedFragment({
  inline: false,
  fragmentType: 'Section',
  fragmentAlias: 'PromoVisnavSection'
}).shape({
  id: string(),
  title: string(),
  theme: string(),
  isCarousel: bool(),
  itemTheme: string(),
  components: arrayOf(shape({ ...PromoVisualNavigation.dataModel.component })),
});

const bannerUnionShape = shape({
  ...MedioInline.dataModel.component,
  ...PiccolaPencil.dataModel.component,
  ...MedioComposableBanner.dataModel.component,
  ...PiccolaComposableBanner.dataModel.component
});

const medioBannerShape = shape({
  ...MedioInline.dataModel.component,
  ...MedioComposableBanner.dataModel.component
});

const piccolaBannerShape = shape({
  ...PiccolaPencil.dataModel.component,
  ...PiccolaComposableBanner.dataModel.component
});

export const educationalLayoutFragment = fragment().shape({
  ...baseLayoutFragment,
  descriptionNew: shape({
    title: string(),
    descriptionText: string(),
    id: string(),
  }),
  dynamicSections: arrayOf(dynamicSectionsFragment),
  medioInline1: medioBannerShape,
  medioInline2: medioBannerShape,
  piccolaPencil1: piccolaBannerShape,
  piccolaPencil2: piccolaBannerShape,
  promoVisualNavigation1: promoVisNavSectionNamedFragment,
  promoVisualNavigation2: promoVisNavSectionNamedFragment,
  promoVisualNavigation3: promoVisNavSectionNamedFragment,
  resourcesSection: resourcesSectionNamedFragment,
  sideNavigation: shape(SideNavigation.dataModel.component),
  sponsoredSkyscraperBanner: shape({
    __typename: string(),
    customRenderId: string()
  }),
  visualNavigation1: visNavSectionNamedFragment,
  visualNavigation2: visNavSectionNamedFragment,
  visualNavigation3: visNavSectionNamedFragment,
  internalMarketingBanner1: bannerUnionShape,
  internalMarketingBanner2: bannerUnionShape,
  internalMarketingBanner3: bannerUnionShape
});

export const navigationalLayoutFragment = fragment().shape({
  ...baseLayoutFragment,
  descriptionNew: shape({
    title: string(),
    descriptionText: string()
  }),
  dynamicSections: arrayOf(dynamicSectionsFragment),
  medioInline1: medioBannerShape,
  medioInline2: medioBannerShape,
  medioInline3: medioBannerShape,
  piccolaPencil1: piccolaBannerShape,
  piccolaPencil2: piccolaBannerShape,
  piccolaPencil3: piccolaBannerShape,
  promoVisualNavigation1: promoVisNavSectionNamedFragment,
  promoVisualNavigation2: promoVisNavSectionNamedFragment,
  promoVisualNavigation3: promoVisNavSectionNamedFragment,
  resourcesSection: resourcesSectionNamedFragment,
  sideNavigation: shape(SideNavigation.dataModel.component),
  sponsoredSkyscraperBanner: shape({
    __typename: string(),
    customRenderId: string()
  }),
  visualNavigation1: visNavSectionNamedFragment,
  visualNavigation2: visNavSectionNamedFragment,
  visualNavigation3: visNavSectionNamedFragment,
  visualNavigation4: visNavSectionNamedFragment,
  visualNavigation5: visNavSectionNamedFragment,
  visualNavigation6: visNavSectionNamedFragment,
  internalMarketingBanner1: bannerUnionShape,
  internalMarketingBanner2: bannerUnionShape,
  internalMarketingBanner3: bannerUnionShape
});

export const inspirationalLayoutFragment = fragment().shape({
  ...baseLayoutFragment,
  descriptionNew: shape({
    title: string(),
    descriptionText: string()
  }),
  dynamicSections: arrayOf(dynamicSectionsFragment),
  gallery: shape({ ...gallerySectionFragment }),
  medioInline1: medioBannerShape,
  medioInline2: medioBannerShape,
  piccolaPencil1: piccolaBannerShape,
  piccolaPencil2: piccolaBannerShape,
  promoVisualNavigation1: promoVisNavSectionNamedFragment,
  promoVisualNavigation2: promoVisNavSectionNamedFragment,
  promoVisualNavigation3: promoVisNavSectionNamedFragment,
  resourcesSection: resourcesSectionNamedFragment,
  sideNavigation: shape(SideNavigation.dataModel.component),
  sponsoredSkyscraperBanner: shape({
    __typename: string(),
    customRenderId: string()
  }),
  visualNavigation1: visNavSectionNamedFragment,
  visualNavigation2: visNavSectionNamedFragment,
  internalMarketingBanner1: bannerUnionShape,
  internalMarketingBanner2: bannerUnionShape,
  internalMarketingBanner3: bannerUnionShape,
  internalMarketingBanner4: bannerUnionShape
});

export const universalLayoutFragment = fragment().shape({
  ...baseLayoutFragment,
  // TODO: see about getting nullability shapes
  // consistent with base 3
  flexibleCluster1: arrayOf(dynamicSectionsFragment),
  flexibleCluster2: arrayOf(dynamicSectionsFragment),
  flexibleCluster3: arrayOf(dynamicSectionsFragment),
  internalMarketingBanner1: bannerUnionShape,
  internalMarketingBanner2: bannerUnionShape,
  layoutType: string(),
  promoVisualNavigation1: promoVisNavSectionNamedFragment,
  promoVisualNavigation2: promoVisNavSectionNamedFragment,
  promoVisualNavigation3: promoVisNavSectionNamedFragment,
  resourceSection: resourcesSectionNamedFragment,
  richText: arrayOf(shape({
    title: string(),
    descriptionText: string()
  })),
  sideNavigation: shape(SideNavigation.dataModel.component),
  sponsoredSkyscraperBanner: shape({
    __typename: string(),
    customRenderId: string()
  }),
  visualNavigation1: visNavSectionNamedFragment,
  visualNavigation2: visNavSectionNamedFragment,
  visualNavigation3: visNavSectionNamedFragment,
  visualNavigation4: visNavSectionNamedFragment,
  visualNavigation5: visNavSectionNamedFragment,
  visualNavigation6: visNavSectionNamedFragment
  // gallery: shape({ ...gallerySectionFragment }),
  // medioInline1: shape({ ...MedioInline.dataModel.component }),
  // medioInline2: shape({ ...MedioInline.dataModel.component }),
  // piccolaPencil1: shape({ ...PiccolaPencil.dataModel.component }),
  // piccolaPencil2: shape({ ...PiccolaPencil.dataModel.component }),
});

export const breadcrumbFragment = {
  breadcrumbs: shape({
    Breadcrumbs: fragment().shape({
      breadcrumbItem: arrayOf(shape({
        label: string(),
        url: string()
      }))
    })
  })
};
