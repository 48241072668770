import React from 'react';
import { string, shape as shapeType } from 'prop-types';
import { ResourceRenderer } from './ResourceRenderer';
import { Resource } from './Resource';

const ResourcePreview = ({
  componentId,
  componentClass = '',
  livePreviewData = null
}) => {

  return (
    livePreviewData
      ? (
        <ResourceRenderer
          componentId={componentId}
          componentClass={componentClass}
          dataComponent={livePreviewData}
        />
      )
      : (
        <Resource
          componentId={componentId}
          componentClass={componentClass}
        />
      )
  );
};

ResourcePreview.propTypes = {
  componentId: string.isRequired,
  componentClass: string,
  livePreviewData: shapeType({
    id: string,
    title: string,
    altText: string,
    link: string,
    thumbNailImage: string,
    image: shapeType({
      damDownloadedContent: shapeType({
        url: string
      })
    }),
    video: string,
    previewImage: shapeType({}),
    description: string,
    eyebrow: string
  }),
};

export { ResourcePreview };
