import React from 'react';
import { string, number, bool } from 'prop-types';
import {
  arrayOf,
  customType,
  namedFragment,
  typename,
  params,
  shape,
  string as stringType,
  useDataModel
} from '@thd-nucleus/data-sources';
import { ContentGalleryCardRenderer } from './ContentGalleryCardRenderer';

const ContentGalleryCard = ({
  componentId,
  componentClass,
  componentPosition = 1,
  displayTitle = true,
  lazyLoad = false,
}) => {

  const { data, error } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass
    }
  });

  return (
    <ContentGalleryCardRenderer
      componentId={componentId}
      componentClass={componentClass}
      componentPosition={componentPosition}
      displayTitle={displayTitle}
      lazyLoad={lazyLoad}
      dataComponent={data?.component}
      error={error}
      componentName={ContentGalleryCard.displayName}
    />
  );
};

ContentGalleryCard.propTypes = {
  componentId: string.isRequired,
  componentClass: string.isRequired,
  componentPosition: number,
  displayTitle: bool,
  lazyLoad: bool
};

ContentGalleryCard.displayName = 'ContentGalleryCard';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1'
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: stringType()
      })
    )
  }),
  damDownloadedContent: shape({
    url: stringType()
  }),
  id: stringType(),
  __typename: typename('DamMedia')
});

ContentGalleryCard.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['Gallery']).isRequired()
  }).shape({
    Gallery: namedFragment({
      inline: true,
      fragmentType: 'Gallery'
    }).shape({
      id: stringType(),
      componentName: stringType(),
      headline: stringType(),
      link: stringType(),
      altText: stringType(),
      previewImage: DamMediaFragment
    })
  })
};

export { ContentGalleryCard };
