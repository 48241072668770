import React from 'react';
import { string, bool } from 'prop-types';
import {
  params,
  arrayOf as arrayType,
  string as stringType,
  fragment,
  shape,
  customType,
  useDataModel
} from '@thd-nucleus/data-sources';
import { ProductShelfRenderer } from './ProductShelfRenderer';

const ProductShelf = ({
  componentId,
  componentClass,
  showTooFewProductsNotice,
  theme
}) => {

  const {data, loading, error} = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass
    }
  });

  if (!data || loading || error) {
    return null;
  }

  return (
    <ProductShelfRenderer
      componentId={componentId}
      dataComponent={data?.component}
      showTooFewProductsNotice={showTooFewProductsNotice}
      theme={data?.component?.theme || theme}
    />
  );
}

ProductShelf.propTypes = {
  componentId: string.isRequired,
  componentClass: string,
  showTooFewProductsNotice: bool,
  theme: string
};

ProductShelf.defaultProps = {
  componentClass: 'ProductShelf',
  showTooFewProductsNotice: false,
  theme: null
};

ProductShelf.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['ProductShelf']).isRequired()
  }).shape({
    ProductShelf: fragment().shape({
      id: stringType(),
      componentName: stringType(),
      sortBy: stringType(),
      publishDate: stringType(),
      sortOrderOptions: stringType(),
      theme: stringType(),
      title: stringType(),
      productShelfData: shape({
        navParam: stringType(),
        skus: arrayType(stringType())
      })
    })
  })
};

export { ProductShelf };
