import React, { useEffect, useState } from 'react';
import { string, object, shape, arrayOf } from 'prop-types';
import { RetailMediaCarousel } from '../RetailMediaCarousel';
import { cleanString } from '../../utils';

const BasicPLA = ({
  breadCrumbs, browserId, userId, categorySourceId, customRenderId, pageContext, nValue, schema, sponsoredConfig, plaLocation
}) => {
  const formattedCustomRenderId = cleanString(customRenderId);
  const sponsoredConfigKeys = (sponsoredConfig && Object.keys(sponsoredConfig)) ?? [];

  // Try to find a key in the sponsoredConfig that includes the formattedCustomRenderId.
  // Example:
  //   formattedCustomRenderId:  sponsoredbottomcarousel
  //   keyProp:  sponsoredBottomCarousel-N-5yc1vZar4i
  // --- ---
  const keyProp = sponsoredConfigKeys.find((key) => cleanString(key).includes(formattedCustomRenderId)) ?? '';

  // try to extract the N-Value from the keyProp
  // Example:
  //   keyProp:  sponsoredBottomCarousel-N-5yc1vZar4i
  //   matchedNvalue: N-5yc1vZar4i
  // --- ---
  const match = keyProp.match(/.*?-([Nn]-[\w\d]+)$/);
  const matchedNvalue = match ? match[1] : '';

  // set the new slug id
  const newSlugId = nValue || matchedNvalue || '';

  // This is needed because, if the nValue was passed, we need to match the
  // key that matches 'customRenderId-nValue'
  const key = `${customRenderId}-${newSlugId}`;
  const configKey = sponsoredConfigKeys.find((cKey) => cleanString(cKey) === cleanString(key));
  const configObj = configKey && sponsoredConfig[configKey];

  const [component, setComponent] = useState(null);

  const plaToDisplay = () => {
    if (!configObj) return null;
    const { activeAdType } = configObj;
    let activeConfig = configObj?.adConfigs[activeAdType];
    return (
      <RetailMediaCarousel
        {...{
          ...activeConfig
        }}
      />
    );
  };

  const getPLAToDisplay = () => {
    setComponent(plaToDisplay());
  };

  useEffect(() => {
    getPLAToDisplay();
  }, []);

  if (component) {
    return <div id="basic-pla" data-testid="basic-pla">{component}</div>;
  }
  return (
    <div id="basic-pla" data-testid="basic-pla">
      <RetailMediaCarousel
        {...{
          plaLocation,
          breadCrumbs,
          browserId,
          userId,
          categorySourceId,
          customRenderId,
          pageContext,
          schema,
          nValue: newSlugId
        }}
      />
    </div>
  );

};

BasicPLA.propTypes = {
  plaLocation: string,
  breadCrumbs: arrayOf(shape({})),
  browserId: string,
  userId: string,
  categorySourceId: string,
  schema: string,
  pageContext: shape({}),
  nValue: string,
  customRenderId: string,
  sponsoredConfig: object
};

BasicPLA.defaultProps = {
  plaLocation: '',
  breadCrumbs: [],
  browserId: '',
  userId: '',
  categorySourceId: null,
  pageContext: {},
  schema: '',
  nValue: '',
  customRenderId: '',
  sponsoredConfig: {}
};

export { BasicPLA };
