import { useDataModel } from '@thd-nucleus/data-sources';
import { useRef } from 'react';
import { useStore } from '@thd-nucleus/experience-context';
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import { getParams } from './productShelfHookHelpers';

function getHookOptions(params, storeId, storeZip) {
  const usableStoreId = params.storeId || storeId;
  const usableStoreZip = storeZip;

  return {
    channel: params.channel,
    navParam: params.navParam,
    keyword: '',
    orderBy: params.orderBy,
    itemIds: params.itemIds,
    pageSize: 24,
    startIndex: 0,
    storeId: usableStoreId,
    zipCode: usableStoreZip
  };
}

function shouldSkip(hookOpts) {
  return (!hookOpts.keyword && !hookOpts.navParam && !hookOpts.itemIds) || hookOpts.startIndex >= 24;
}

function filterSkus(skus) {
  return skus.filter(sku => !sku.fulfillment || podFulFillmentUtils.isATCEnabled(sku));
}

function updateLoadedStoreZip(variables, loadedStoreZip) {
  if (variables?.zipCode || variables?.storeZip) {
    loadedStoreZip.current = variables?.zipCode || variables.storeZip;
  }
}

function updateLoadedStoreId(variables, loadedStoreId) {
  if (variables?.storeId) {
    loadedStoreId.current = variables.storeId;
  }
}

function calculateTotalProducts(searchReport, maxLimit = 24) {
  const total = searchReport?.totalProducts ?? 0;
  return Math.min(total, maxLimit);
}

export const usePsSearchModel = (props) => {
  const { storeId, storeZip } = useStore();
  const {opts, channel, storeId: propStoreId} = props;
  const params = getParams({
    opts,
    channel,
    propStoreId
  });

  const hookOpts = getHookOptions(params, storeId, storeZip);
  const skuRef = useRef([]);
  const storedMetadata = useRef({});
  const loadedStoreId = useRef(hookOpts.storeId);
  const loadedStoreZip = useRef(hookOpts.zipCode);

  const { data, loading, error, variables } = useDataModel('psSearchModel', {
    variables: {
      ...hookOpts,
      additionalSearchParams: {
        callback: JSON.stringify(hookOpts.orderBy)
      }
    },
    skip: shouldSkip(hookOpts),
    ssr: true
  });

  if (loading) {
    return {
      loading, storeId: loadedStoreId.current, storeZip: loadedStoreZip.current, skus: skuRef.current, ...storedMetadata.current
    };
  }

  if (error) {
    console.log(error);
  }

  updateLoadedStoreId(variables, loadedStoreId);

  updateLoadedStoreZip(variables, loadedStoreZip);

  // logic parsing response here:
  let skus = data?.searchModel?.products || [];
  let categoryLabel = '';
  let shopAllLink = '';
  let totalProducts = calculateTotalProducts(data?.searchModel?.searchReport);

  if (skus?.length > 0) {
    const { metadata = {}, searchReport = {} } = data?.searchModel;
    const sortByResponseParam = searchReport?.sortBy;
    const { canonicalUrl } = (hookOpts.navParam && metadata) || {};
    shopAllLink = canonicalUrl
      ? `${canonicalUrl}?sortby=${sortByResponseParam}&catStyle=ShowProducts`
      : '';
    const contentfulTitle = props?.opts?.contentfulTitle;
    if ( contentfulTitle ) {
      categoryLabel = contentfulTitle;
    }
    skus = filterSkus(skus);
  }

  if (hookOpts.itemIds) {
    totalProducts = skus.length;
    skuRef.current = [...skus];
  } else {
    skuRef.current.splice(hookOpts.startIndex, hookOpts.pageSize, ...skus);
  }

  storedMetadata.current = {
    shopAllLink,
    categoryLabel,
    totalProducts
  };

  return {
    loading,
    skus: skuRef.current,
    shopAllLink,
    categoryLabel,
    storeId: loadedStoreId.current,
    storeZip: loadedStoreZip.current,
    totalProducts
  };
};