import React, { useEffect } from 'react';
import {
  arrayOf,
  bool,
  number,
  string,
  shape
} from 'prop-types';
import { SpotlightRenderer } from './SpotlightRenderer';
import { Spotlight } from './Spotlight';

const SpotlightPreview = ({
  componentId,
  componentClass = '',
  componentPosition = 1,
  showDescription = false,
  useB2bStyles = false,
  proAnalyticsData = {},
  lazyLoad = false,
  orientation = 'vertical',
  livePreviewData = null
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('spotlightPreview.ready');
  }, []);

  let {
    richTextContent,
    linkListCollection,
  } = livePreviewData || {};

  const listOfLinks = linkListCollection?.items || [];

  return (
    livePreviewData
      ? (
        <SpotlightRenderer
          componentId={componentId}
          componentClass={componentClass}
          componentPosition={componentPosition}
          data={livePreviewData}
          lazyLoad={lazyLoad}
          listOfLinks={listOfLinks}
          orientation={orientation}
          proAnalyticsData={proAnalyticsData}
          richText={richTextContent?.json}
          showDescription={showDescription}
          useB2bStyles={useB2bStyles}
          isLivePreview
        />
      )
      : <Spotlight componentId={componentId} componentClass={componentClass} />
  );
};

SpotlightPreview.displayName = 'SpotlightPreview';

SpotlightPreview.propTypes = {
  componentId: string.isRequired,
  componentPosition: number,
  componentClass: string,
  showDescription: bool,
  useB2bStyles: bool,
  proAnalyticsData: shape({
    isB2B: bool,
    templates: string,
  }),
  lazyLoad: bool,
  orientation: string,
  livePreviewData: shape({
    id: string,
    title: string,
    altText: string,
    cta: string,
    link: string,
    componentName: string,
    description: string,
    richTextContent: string,
    proAnalyticsCampaign: string,
    proAnalyticsComponent: string,
    linkList: arrayOf(shape({
      label: string,
      href: string,
    })),
    videoUrl: string,
    linkListCollection: arrayOf(shape({
      href: string,
      internalName: string,
      label: string,
    })),
    previewImage: shape({
      damContentSelector: shape({
        assetData: arrayOf(
          shape({
            selectedImageUrl: string
          })
        )
      }),
      damDownloadedContent: shape({
        url: string
      }),
    }),
  }),
};

export { SpotlightPreview };
