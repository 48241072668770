import React from 'react';
import { node, string } from 'prop-types';
import { DynamicComponent } from '@thd-nucleus/app-render';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { Hydrator } from '@thd-olt-component-react/hydrator';
import { HYDRATION_STANDARD_SCROLL_BUFFER, HYDRATION_STANDARD_DELAY } from '../../../constants';

/**
 * This component is responsible for deciding whether to render the children components. If the children components
 * exist AND they are not a fragment, this component will render, otherwise nothing renders. When the children are
 * rendered, it wraps the children components in a DynamicComponent and Hydrator to handle the hydration of the
 * components.
 *
 * @param {string} componentId - The unique identifier for the child component.
 * @param {node} children - The children components.
 * @param {string} key - The key for the children components.
 * @param {string} pageType - The layout type these children components belong to. See `dynamicRendering` in
 *                            src/server.js. Possible values are 'educationalLayout', 'inspirationalLayout', and
 *                           'navigationalLayout'.
 * @param {string} templateSectionStyle - The style to apply to the section as defined for the layout.
 * @returns {JSX.Element} The rendered component.
 */
export const RenderResolver = ({
  componentId, children, pageType, templateSectionStyle
}) => {

  // We do not want to render a fragment that will take up space and cause Cumulative Layout Shift (CLS).
  // Note: `type()` will invoke the component function which will give us access to the component type. The side effect
  // of this is that propType validation will be triggered and since some components have required properties, which we
  // do not pass in at this time, we will get warnings in the console. To prevent these warnings, any props needed on a
  // component are passed in using the customProps object in parseContent.
  // (See: src/contentful/utils/utilities#parseContent())
  if (!children || React.Children.toArray(children)?.[0]?.type() === React.Fragment) {
    return null;
  }

  return (
    <ErrorBoundary key={componentId} name={componentId}>
      <div className={templateSectionStyle}>
        <DynamicComponent
          bypassFeatureSwitch
          pageType={pageType}
          placeholder={(
            <Placeholder
              width="100%"
              height="200px"
            />
          )}
        >
          <Hydrator
            id={`${componentId}-hydrator`}
            scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
            delay={HYDRATION_STANDARD_DELAY}
            placeholder={(
              <Placeholder
                width="100%"
                height="200px"
              />
            )}
          >
            {children}
          </Hydrator>
        </DynamicComponent>
      </div>
    </ErrorBoundary>
  );
};

RenderResolver.propTypes = {
  children: node,
  componentId: string.isRequired,
  pageType: string.isRequired,
  templateSectionStyle: string.isRequired
};

RenderResolver.defaultProps = {
  children: null
};
