/* eslint-disable react/prop-types */
import React from 'react';
import {
  ProductPod,
  ProductATC,
  ProductRating,
  ProductIdentifier, ProductBadge, ProductSponsored,
  ProductImage, PodFooter, PodSection,
  PodSpacer
} from '@thd-olt-component-react/product-pod';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';
import { Price } from '@thd-olt-component-react/price';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import { extend } from '@thd-nucleus/data-sources';
import { bool } from 'prop-types';

const CustomProductPod = ({ itemId, storeId, idx }) => {
  const analyticsData = {
    parent: 'product-shelf',
    position: idx
  };

  const theme = useTheme(CustomProductPod);

  const { hideAddToListButton } = theme.props;
  // TODO: get product pod folks to add sui-text-primary at the root so we dont
  // need to smuggle it in the padding prop
  return (
    <ProductPod
      itemId={itemId}
      analyticsData={analyticsData}
      storeId={storeId}
      padding="sui-text-primary sui-p-2"
      render={(pod) => (
        <>
          <div className="sui-flex sui-items-center sui-justify-between">
            <PodSection noPadding>
              <ProductBadge itemId={pod.itemId} storeId={pod.storeId} />
              <ProductSponsored itemId={pod.itemId} position={idx} />
            </PodSection>
            {!hideAddToListButton && (
              <AddToList itemId={pod.itemId} showIconButton />
            )}
          </div>
          <ProductImage
            itemId={pod.itemId}
            showSecondaryImage={pod.showSecondaryImage}
            onClick={() => {}}
          />
          <PodSection columnAlign alignTop noPadding gap="sui-gap-0">
            <SuperSkuMiniSwatches
              itemId={pod.itemId}
              onChange={pod.onChange}
              onHover={pod.onSSKUHover}
            />
            <ProductRating itemId={pod.itemId} />
            <PodSpacer minHeight="21px">
              <ProductIdentifier itemId={pod.itemId} type="model" />
            </PodSpacer>
            <PodSpacer padding={['TOP', 'BOTTOM']}>
              <ProductHeader
                brand="above"
                itemId={pod.itemId}
                xSmall
                titleMaxLine={3}
                brandMaxLine={1}
                disableShopThisCollection
              />
            </PodSpacer>
          </PodSection>
          <PodFooter noPadding margin="sui-mt-auto">
              <Price
                itemId={pod.itemId}
                large={false}
                storeId={pod.storeId}
                displayEachUom={false}
                showPreferredPricingBadge
                stackWasPrice
                onSavingsCenterToggle={pod.onSavingsCenterToggle}
                hideForProductTypes={['HDQC']}
                type="simple"
              />
            <PodSpacer minHeight="40px" padding={['TOP']}>
              <ProductATC
                itemId={pod.itemId}
                storeId={pod.storeId}
                checkGeneric
                outline
              />
            </PodSpacer>
          </PodFooter>
        </>
      )}
    />
  );
};

CustomProductPod.dataModel = extend(
  AddToList,
  Price,
  ProductATC,
  ProductBadge,
  ProductHeader,
  ProductIdentifier,
  ProductImage,
  ProductPod,
  ProductRating,
  SuperSkuMiniSwatches
);

CustomProductPod.displayName = 'CustomProductPod';

CustomProductPod.themeProps = {
  hideAddToListButton: bool
};

CustomProductPod.defaultThemeProps = {
  hideAddToListButton: false
};

export { CustomProductPod };
