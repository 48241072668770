import React from 'react';
import PropTypes from 'prop-types';
import {
  useDataModel,
  extend,
  arrayOf,
  namedFragment,
  customType,
  bool as boolType,
  shape,
  string as stringType,
  alias
} from '@thd-nucleus/data-sources';
import { useBreakpoint, ThemeProvider } from '@one-thd/sui-atomic-components';
import { ComponentSelector } from '@thd-olt-component-react/component-selector';
import { getThemeByName } from '@thd-olt-component-react/stencil-themes';
import { Spotlight } from './Spotlight';
import { SpotlightContainerRenderer } from './SpotlightContainerRenderer';
import { SpotlightSelector } from './personalization/SpotlightSelector';

const SpotlightContainer = ({
  componentId,
  componentClass = '',
  componentPosition = 1,
  showDescription = false,
  useB2bStyles = false,
  fontWeight = 'display',
  cmMmcParam = '', // adobe's Campaign Management Multi-Media Channel parameter to be passed to Citi,
  // so that we can track credit card campaigns through application completions
  renderDefault = false,
  variationIds = [],
  isSeoBot = false,
}) => {
  const { current: currentBreakpoint } = useBreakpoint(null, { current: true });

  const { data, loading, error } = useDataModel('specializedContainer', {
    variables: {
      id: componentId,
      componentClass
    }
  });

  if (!data || loading || error) {
    return null;
  }

  let { theme, itemTheme } = data?.component || {};

  const title = data?.component?.title;
  const overflowBehavior = data?.component?.overflowBehavior;
  const extraCardOnLargeAndAbove = data?.component?.extraCardOnLargeAndAbove;
  const spotlightList = data?.component?.spotlightList;
  const cardCount = spotlightList?.length;
  const spotlightOrientation = (
    cardCount === 1
    && currentBreakpoint !== 'unprefixed'
    && currentBreakpoint !== 'sm'
  ) ? 'horizontal' : 'vertical';

  itemTheme = itemTheme || theme;

  const processedTheme = getThemeByName(theme);
  const processedItemTheme = getThemeByName(itemTheme);
  const spotlightComponents = spotlightList.map((spotlight, index) => {
    const isSpotlight = spotlight?.__typename === 'Spotlight';
    const isSelector = spotlight?.__typename === 'ComponentSelector';
    if (isSpotlight) {
      return (
        <Spotlight
          key={spotlight.id}
          componentId={spotlight.id}
          componentClass="Spotlight"
          componentPosition={index + 1}
          showDescription={showDescription}
          useB2bStyles={useB2bStyles}
          orientation={spotlightOrientation}
          cmMmcParam={cmMmcParam}
        />
      );
    }
    if (isSelector) {
      return (
        <ComponentSelector
          componentMap={{ Spotlight }}
          key={spotlight.id}
          componentId={spotlight.id}
          componentClass="ComponentSelector"
          customPropMap={{ Spotlight: {
            useB2bStyles,
            showDescription,
            orientation: spotlightOrientation,
            componentPosition: index + 1,
            cmMmcParam
          } }}
          isInCarousel={overflowBehavior === 'Carousel'}
          defaultComponent={spotlight.defaultComponent}
          variations={spotlight.variations}
          variationIds={variationIds}
          renderDefault={renderDefault}
          isSeoBot={isSeoBot}
          targetingType={spotlight.targetingType}
        />
      );
    }
    return (<React.Fragment key={spotlight.id} />);
  }).map((spotlight, index) => {
    return (
      <ThemeProvider theme={processedItemTheme}>
        {spotlight}
      </ThemeProvider>
    );
  });

  return (
    <ThemeProvider theme={processedTheme}>
      <SpotlightContainerRenderer
        componentId={componentId}
        componentPosition={componentPosition}
        title={title}
        overflowBehavior={overflowBehavior}
        extraCardOnLargeAndAbove={extraCardOnLargeAndAbove}
        cardCount={cardCount}
        spotlightComponents={spotlightComponents}
        fontWeight={fontWeight}
      />
    </ThemeProvider>
  );
};

SpotlightContainer.displayName = 'SpotlightContainer';
SpotlightContainer.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string,
  componentPosition: PropTypes.number,
  showDescription: PropTypes.bool,
  useB2bStyles: PropTypes.bool,
  fontWeight: PropTypes.string,
  cmMmcParam: PropTypes.string,
  renderDefault: PropTypes.bool,
  isSeoBot: PropTypes.bool,
  variationIds: PropTypes.arrayOf(
    PropTypes.shape({
      provider: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      mbox: PropTypes.string,
      componentID: PropTypes.string,
      uniqueID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      slotNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      variation: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
};

SpotlightContainer.dataModel = extend({
  specializedContainer: alias('component').params({
    id: stringType()
      .isRequired(),
    componentClass: customType('ComponentClass')
      .enum(['SpotlightContainer'])
      .isRequired(),
  }).shape({
    SpotlightContainer: namedFragment({
      inline: true,
      fragmentType: 'SpotlightContainer'
    }).shape({
      id: stringType(),
      title: stringType(),
      componentName: stringType(),
      overflowBehavior: boolType(),
      extraCardOnLargeAndAbove: boolType(),
      theme: stringType(),
      itemTheme: stringType(),
      spotlightList: arrayOf(shape({
        ...SpotlightSelector.dataModel.component,
        ...Spotlight.dataModel.component,
      })),
    }),
  }),
}, Spotlight);

export { SpotlightContainer };
