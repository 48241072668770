import React from 'react';
import classNames from 'classnames/bind';
import { node, string, bool } from 'prop-types';
import { RenderResolver } from './RenderResolver';
import styles from '../flexible-layout.scss';

/**
 * This component is a shorthand for the RenderResolver component. It is specifically for the flexible layout.
 *
 * @param {string} componentId - The unique identifier for the child component.
 * @param {node} children - The children components.
 * @returns {JSX.Element} The rendered component.
 */
export const FlexibleRenderResolver = ({ children, componentId, noTopMargin }) => {
  const cx = classNames.bind(styles);

  return (
    <RenderResolver
      componentId={componentId}
      key={componentId}
      pageType="flexibleLayout"
      templateSectionStyle={cx(
        'flexible-layout__static-section',
        { 'flexible-layout__static-section--no-top-margin': noTopMargin }
      )}
    >
      {children}
    </RenderResolver>
  );
};

FlexibleRenderResolver.propTypes = {
  children: node,
  componentId: string.isRequired,
  noTopMargin: bool
};

FlexibleRenderResolver.defaultProps = {
  children: null,
  noTopMargin: false
};
