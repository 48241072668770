import {
  customType,
  extend,
  params,
  string,
  bool,
  number,
  shape,
  arrayOf,
} from '@thd-nucleus/data-sources';
import { RetailMediaCarouselPod } from './RetailMediaCarouselPod';
import './RetailMediaCarousel.scss';

export const dataModel = extend({
  plaModel: params({
    breadCrumbs: arrayOf(customType('BreadCrumbInput').shape({
      browseUrl: string(),
      label: string()
    })),
    categorySourceId: string(),
    channel: string(),
    customer: customType('Customer').shape({
      mcVisId: number(),
      customerType: string(),
      userId: string(),
      svocId: string(),
    }),
    debug: bool(),
    itemId: string(),
    itemIds: arrayOf(string()),
    keyword: string(),
    nValue: string(),
    requestedAdCount: number(),
    schema: string(),
    storeId: string(),
    zipCode: string(),
    plaLocation: string(),
    carouselType: string(),
    isBrandPricingPolicyCompliant: bool(),
  }).shape({
    products: arrayOf(extend(
      shape({
        itemId: string(),
        identifiers: shape({
          canonicalUrl: string(),
        }),
        info: shape({
          sponsoredMetadata: shape({
            campaignId: string(),
            placementId: string(),
            slotId: string(),
            sponsoredId: string(),
            trackSource: string()
          }),
          sponsoredBeacon: shape({
            onClickBeacons: arrayOf(string()),
            onViewBeacons: arrayOf(string())
          }),
        })
      }),
      shape(RetailMediaCarouselPod.dataModel.product),
    )),
    sponsoredReport: shape({
      numberOfSponsoredProducts: number(),
      numberOfMissingProductInformationProducts: number(),
      numberOfNonAvailableProducts: number(),
    }),
    carouselMetadata: shape({
      title: string(),
    }),
  })
});
