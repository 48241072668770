import React from 'react';
import { string, bool, shape } from 'prop-types';
import { ProductShelfRenderer } from './ProductShelfRenderer';
import { ProductShelf } from "./ProductShelf";

const ProductShelfPreview = ({
componentId,
componentClass,
showTooFewProductsNotice,
livePreviewData
}) => {

  return (
    livePreviewData ?
      <ProductShelfRenderer
        componentId={componentId}
        dataComponent={livePreviewData}
        showTooFewProductsNotice={showTooFewProductsNotice}
      />
      : <ProductShelf
        componentId={componentId}
        componentClass={componentClass}
      />
  );
};

ProductShelfPreview.propTypes = {
  componentId: string.isRequired,
  componentClass: string,
  showTooFewProductsNotice: bool,
  livePreviewData: shape({})
};

ProductShelfPreview.defaultProps = {
  componentClass: 'ProductShelf',
  showTooFewProductsNotice: false,
  livePreviewData: {}
};

ProductShelfPreview.dataModel = ProductShelf.dataModel;

export { ProductShelfPreview };
