import React from 'react';
import PropTypes from 'prop-types';
import { VisualNavigationRenderer } from './VisualNavigationRenderer';
import { VisualNavigation } from './VisualNavigation';

const VisualNavigationPreview = ({
  componentId,
  componentClass = '',
  componentPosition = 1,
  isInSection = false,
  lazyLoad = false,
  experienceHasSideNav = false,
  livePreviewData = null
}) => {

  return (
    livePreviewData
      ? (
        <VisualNavigationRenderer
          componentId={componentId}
          componentPosition={componentPosition}
          componentClass={componentClass}
          isInSection={isInSection}
          lazyLoad={lazyLoad}
          experienceHasSideNav={experienceHasSideNav}
          dataComponent={livePreviewData}
          visNavList={livePreviewData?.visualNavigationListCollection?.items}
          displayName={VisualNavigationPreview.displayName}
        />
      )
      : (
        <VisualNavigation
          componentId={componentId}
          componentClass={componentClass}
          componentPosition={componentPosition}
          isInSection={isInSection}
          lazyLoad={lazyLoad}
          experienceHasSideNav={experienceHasSideNav}
        />
      )
  );
};

VisualNavigationPreview.displayName = 'VisualNavigationPreview';
VisualNavigationPreview.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string,
  componentPosition: PropTypes.number,
  isInSection: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  experienceHasSideNav: PropTypes.bool,
  livePreviewData: PropTypes.shape({
    visualNavigationListCollection: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({}))
    })
  })
};

export { VisualNavigationPreview };
