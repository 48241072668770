import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { Carousel } from '@one-thd/sui-carousel';
import { Typography } from '@one-thd/sui-atomic-components';
import { configureContainerForCarousel, configureContainerForWrap } from './utils/spotlightContainerConfig';

const SpotlightContainerRenderer = ({
  componentId,
  componentPosition = 1,
  title = '',
  overflowBehavior = 'Carousel',
  extraCardOnLargeAndAbove = false,
  cardCount = 0,
  spotlightComponents = [],
  fontWeight = 'display',
}) => {

  const spotlightContainerTitle = (
    <Typography
      variant="h2"
      weight={fontWeight}
      height="snug"
      color="primary"
      uppercase
      data-contentful-entry-id={componentId}
      data-contentful-field-id="title"
    >{title}
    </Typography>
  );

  const wrapContainer = (
    <div data-testid="spotlight-wrap-container" className="sui-bg-primary">
      {title && (
        <div data-component-id={componentId} className="sui-pb-4">
          {spotlightContainerTitle}
        </div>
      )}
      <div
        className={classNames(
          'sui-grid', 'sui-gap-4', 'md:sui-gap-6', 'xl:sui-gap-8',
          configureContainerForWrap(cardCount, extraCardOnLargeAndAbove))}
        data-contentful-entry-id={componentId}
        data-contentful-field-id="spotlightList"
      >
        {spotlightComponents}
      </div>
    </div>
  );

  const carouselContainer = (
    <div data-testid="spotlight-carousel-container" className="sui-bg-primary">
      <Carousel
        title={(
          <span
            data-contentful-entry-id={componentId}
            data-contentful-field-id="title"
          >
            {spotlightContainerTitle}
          </span>
        )}
        breakpoints={configureContainerForCarousel(cardCount, extraCardOnLargeAndAbove)}
        slidesPerView={1}
        slidesPerGroup={1}
        spaceBetween={null}
        hidePaginationOnOneOfOne
        disableMargin
      >
        {spotlightComponents}
      </Carousel>
    </div>
  );

  return (
    <ImpressionProvider
      key={componentPosition}
      data={{
        id: componentId,
        component: 'Section',
        name: 'Section',
        type: 'content'
      }}
    >
      {overflowBehavior === 'Wrap' ? wrapContainer : carouselContainer}
    </ImpressionProvider>
  );
};

SpotlightContainerRenderer.displayName = 'SpotlightContainerRenderer';
SpotlightContainerRenderer.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentPosition: PropTypes.number,
  title: PropTypes.string,
  overflowBehavior: PropTypes.string,
  extraCardOnLargeAndAbove: PropTypes.bool,
  cardCount: PropTypes.number,
  spotlightComponents: PropTypes.arrayOf(PropTypes.element),
  fontWeight: PropTypes.string,
};

export { SpotlightContainerRenderer };
