const getOrderfield = (sortBy = '') => {
  switch (sortBy) {
  case 'Best Match':
    return 'MOST_POPULAR';
  case 'Top Rated':
    return 'TOP_RATED';
  case 'Product Name':
    return 'PRODUCT_NAME';
  case 'Brand Name':
    return 'BRAND_NAME';
  case 'Price':
    return 'PRICE';
  case 'Most Popular':
    return 'MOST_POPULAR';
  case 'New Items':
    return 'NEW_ITEMS';
  default:
    return 'TOP_SELLERS';
  }
}

export const getParams = ({opts, channel, propStoreId: storeId}) => {
  const NEW_ITEM_PARAM = '+4295117280';
  const NAV_FILTER_PARAM = '10000003+';
  const { sortBy, itemIds } = opts;
  let { navParam } = opts;
  let params = {};
  params.itemIds = [];
  let orderField = getOrderfield(sortBy);
  if (sortBy === 'New Items') {
    navParam += NEW_ITEM_PARAM;
  }
  

  if (itemIds && itemIds.length > 0) {
    params.channel = channel?.toUpperCase()
    params.itemIds = itemIds;
  } else if (navParam) {
    params.channel = channel?.toUpperCase();
    params.navParam = navParam?.indexOf('10000003') === -1 ? `${NAV_FILTER_PARAM}${navParam}` : navParam;
  }
  params.navParam = params.navParam || '';
  params.storeId = storeId;
  params.pageSize = parseInt(opts.pageSize, 10);
  params.startIndex = 0;
  params.filter = {
    storeFilter: 'ALL'
  };
  params.orderBy = {
    field: orderField,
    order: opts.sortOrder === 'Descending'
      ? 'DESC'
      : 'ASC'
  };

  return params;
};
