import React, { useEffect } from 'react';
import { string, number, bool, shape } from 'prop-types';
import { useImpression } from '@thd-olt-component-react/impression';
import {
  Link,
  Typography,
  Card,
  CardMedia,
  CardTitle
} from '@one-thd/sui-atomic-components';
import classNames from 'classnames/bind';
import styles from './content-gallery-card.scss';

const akamaiImageResize = (imageUrlToTransform) => {
  const queryParams = 'Resize=(703,703)';

  if (!imageUrlToTransform) {
    return imageUrlToTransform;
  }
  const isAkamaiHost = imageUrlToTransform.includes('thdstatic.com');
  if (!isAkamaiHost) {
    return imageUrlToTransform;
  }
  if (!imageUrlToTransform.includes('?')) {
    return `${imageUrlToTransform}?im=${queryParams}`;
  }
  if (imageUrlToTransform.includes('im=')) {
    const urlBeforeImParams = imageUrlToTransform.substring(0, imageUrlToTransform.indexOf('im='));
    const imAndRemainderParams = imageUrlToTransform.substring(imageUrlToTransform.indexOf('im='));
    const hasAdditionalParams = imAndRemainderParams.includes('&');
    if (hasAdditionalParams) {
      const remainderOfParams = imAndRemainderParams.substring(imAndRemainderParams.indexOf('&'));
      const imParams = imAndRemainderParams.substring(0, imAndRemainderParams.indexOf('&'));
      return `${urlBeforeImParams}${imParams};${queryParams}${remainderOfParams}`;
    }
    const imParams = imageUrlToTransform.substring(imageUrlToTransform.indexOf('im='));
    return `${urlBeforeImParams}${imParams};${queryParams}`;
  }
  return `${imageUrlToTransform}&im=${queryParams}`;
};

const ContentGalleryCardRenderer = ({
  componentId,
  componentClass,
  componentPosition = 1,
  displayTitle = true,
  lazyLoad = false,
  dataComponent = {},
  error = null,
  componentName = 'ContentGalleryCard'
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('content-gallery-card.ready');
  }, []);
  const cx = classNames.bind(styles);

  const { ref, clickID } = useImpression({ data: {
    id: componentId,
    name: componentName,
    component: componentName,
    position: componentPosition,
    type: 'content'
  } });

  const {
    link: linkProductData,
    altText,
    previewImage: dataComponentPreviewImage
  } = dataComponent || {};
  let imageUrl = '';

  if (dataComponentPreviewImage) {
    const damDownloadedContentImage = dataComponentPreviewImage.damDownloadedContent;
    const damContentSelectorImage = dataComponentPreviewImage.damContentSelector?.assetData?.[0];

    if (damDownloadedContentImage?.url) {
      imageUrl = damDownloadedContentImage?.url;
    } else {
      imageUrl = damContentSelectorImage?.selectedImageUrl || imageUrl;
    }
  }

  imageUrl = akamaiImageResize(imageUrl);

  const headline = displayTitle ? (
    <CardTitle header={(
      <Typography
        variant="h3"
        weight="bold"
        height="tight"
        color="primary"
        lineClamp="3"
        className="sui-pb-1"
        data-contentful-entry-id={componentId}
        data-contentful-field-id="headline"
      >
        {dataComponent?.headline}
      </Typography>
    )}
    />
  ) : <></>;

  return (
    linkProductData
      ? (
        <Link
          href={linkProductData}
          className={cx('sui-bg-primary sui-border-primary',
            'hover:sui-shadow-md sui-grid sui-flex sui-flex-col sui-grow sui-no-underline link--hover-no-underline')}
          data-component-id={componentId}
          id={`${componentClass}-${componentPosition}-${componentId}`}
          data-component-name={componentName}
          data-component-position={componentPosition}
          data-component={componentName}
          ref={ref}
          // eslint-disable-next-line react/no-unknown-property
          clickid={clickID}
        >
          {error && <div>{JSON.stringify(error)}</div>}
          <Card className="sui-col-span-6 sui-grow">
            <CardMedia
              aspect="square"
              src={imageUrl}
              alt={altText || headline}
              loading={lazyLoad ? 'lazy' : ''}
              data-contentful-entry-id={componentId}
              data-contentful-field-id="previewImage"
            />
            {displayTitle && headline}
          </Card>
        </Link>
      )
      : (
        <Card
          className="sui-flex-col sui-grow"
          data-component-id={componentId}
          id={`${componentClass}-${componentPosition}-${componentId}`}
          data-component-name={componentName}
          data-component-position={componentPosition}
          data-component={componentName}
          ref={ref}
        >
          <CardMedia
            aspect="square"
            src={imageUrl}
            alt={altText || headline}
            loading={lazyLoad ? 'lazy' : ''}
            data-contentful-entry-id={componentId}
            data-contentful-field-id="previewImage"
          />
          {displayTitle && headline}
        </Card>
      )
  );
};

ContentGalleryCardRenderer.propTypes = {
  componentId: string.isRequired,
  componentClass: string.isRequired,
  componentPosition: number,
  displayTitle: bool,
  lazyLoad: bool,
  dataComponent: shape({}),
  error: shape({}),
  componentName: string
};

export { ContentGalleryCardRenderer };
