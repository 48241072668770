import React, { useEffect } from 'react';
import { string, number, bool } from 'prop-types';
import {
  params,
  shape,
  namedFragment,
  customType,
  string as stringType,
  number as numberType,
  useDataModel,
  typename,
  arrayOf
} from '@thd-nucleus/data-sources';
import { StoryHeroRenderer } from './StoryHeroRenderer';

const StoryHero = ({
  cmMmcParam = '', // adobe's Campaign Management Multi-Media Channel parameter to be passed to Citi,
  // so that we can track credit card campaigns through application completions
  componentId,
  componentClass,
  componentPosition = 1,
  hasSidenav = false
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('contentfulhero.ready');
  }, []);

  const { data, loading, error } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass,
    },
    skip: !(componentId && componentClass),
  });

  return (
    <StoryHeroRenderer
      dataComponent={data?.component}
      componentId={componentId}
      componentClass={componentClass}
      componentPosition={componentPosition}
      hasSidenav={hasSidenav}
      cmMmcParam={cmMmcParam}
    />
  );
};

StoryHero.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  componentId: string.isRequired,
  componentClass: string.isRequired,
  componentPosition: number,
  hasSidenav: bool,
  cmMmcParam: string
};

StoryHero.displayName = 'Contentful Story Hero (StoryHero)';

StoryHero.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['Hero']).isRequired(),
  }).shape({
    Hero: namedFragment({ inline: true, fragmentType: 'Hero' }).shape({
      id: stringType(),
      componentName: stringType(),
      colorTheme: stringType(),
      cta: stringType(),
      description: stringType(),
      eyebrow: stringType(),
      legalCopy: shape({
        legalText: stringType(),
        drawer: shape({
          headline: stringType(),
          richTextCopyBlock: stringType(),
        })
      }),
      link: stringType(),
      previewImage: shape({
        id: stringType(),
        damContentSelector: shape({
          assetData: arrayOf(
            shape({
              selectedImageDimensions: stringType(),
              selectedImageUrl: stringType()
            })
          )
        }),
        damDownloadedContent: shape({
          details: shape({
            height: numberType(),
            width: numberType(),
            size: numberType(),
          }),
          url: stringType()
        })
      }),
      title: stringType(),

      // TODO: Future work of needing to combine StoryHeroFields and BrandHeroFields into a single data model.
      //
      // BrandHeroFields
      //
      altText: stringType(),
      altTextForLogoBadge: stringType(),
      backgroundColor: stringType(),
      // textColor is a required field
      textColor: stringType(),
      // buttonColor is a required field
      buttonColor: stringType(),
      logoOrBadge: shape({
        id: stringType(),
        damContentSelector: shape({
          assetData: arrayOf(
            shape({
              selectedImageDimensions: stringType(),
              selectedImageUrl: stringType()
            })
          )
        }),
        damDownloadedContent: shape({
          details: shape({
            height: numberType(),
            width: numberType(),
            size: numberType(),
          }),
          url: stringType()
        })
      }),

      __typename: typename('Hero')
    })
  })
};

export { StoryHero };
